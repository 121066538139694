<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight bbox">
      <div class="flex justify-between">
        <!-- 左侧 -->
        <div class="left_box">
          <div class="menuList">
            <div
              v-for="(item, i) in toolTypeList"
              :key="item.typeCode"
              @click="handleTab(i, item.toolTypeCode)"
              :class="
                isActive == i
                  ? 'menu_item bgwhite text-cut'
                  : 'menu_item text-cut'
              "
            >
              {{ item.toolTypeName }}
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="right_box bbox pt20 plr15 pb20 positionr flex1">
          <div v-if="toolsList.length == 0" class="noMessage positiona">
            <img src="../../assets/images/kong.png" alt="" width="100" />
            <div class="f14 textc mt15">{{ $t("noData") }}</div>
          </div>
          <div class="list text666 f13" v-else>
            <div
              v-for="tool in toolsList"
              :key="tool.toolCode"
              @click="showToolsOnline(tool)"
              class="flex align-center  "
              style="justify-content: space-between;"
            >
              <div>{{ tool.toolName }}</div>
              <div class="upload ml10" v-if="tool.isDown =='1'" @click.stop="downloadFile(tool.url, tool.toolTypeName)">
                <img
                  src="../../assets/images/download.png"
                  alt=""
                  width="18"
                  height="18"
                  class="vm"
                />
                <span
                  class="text-blue pl5 vm"
                  
                  >{{ $t("my.download") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      toolTypeList: [],
      isActive: 0,
      nodeList: [1],
      toolsList: [],
    };
  },
  methods: {
    handleTab(i, typeCode) {
      this.isActive = i;
      //显示列表
      var that = this;
      that.toolsList = [];
      this.allToolsList.forEach((element) => {
        if (element.toolTypeCode == typeCode) {
          that.toolsList.push(element);
        }
      });
    },
    showToolsOnline(tool) {
      console.log("tool==>", tool);
      var that = this;
      if (tool.url != undefined && tool.url != "") {
        that.$router.push({
          path: "/previewFile",
          query: {
            url: tool.url,
            content: "",
            typeCode: tool.toolCode,
            typeName: tool.toolName,
            machineType: tool.toolTypeName,
          },
        });
      } else {
        that.$message(tips1);
      }
    },
    loadToolList() {
      //加载工具相关资料
      var that = this;
      var sendData = {};
      this.$http.post("tool/queryToolInfo", sendData).then(function (res) {
        if ((res.data.code = 200)) {
          that.toolTypeList = res.data.data.toolTypeList;
          that.toolsList = res.data.data.toolList;
          that.allToolsList = res.data.data.toolList;
        }
      });
    },
    downloadFile(url, name) {
      console.log("url==>", url);
      const link = document.createElement("a");
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          link.href = URL.createObjectURL(blob);
          // 下载文件的名称及文件类型后缀
          link.download = `${name}`;
          document.body.appendChild(link);
          link.click();
          //在资源下载完成后 清除 占用的缓存资源
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        });
    },
  },
  created() {
    this.loadToolList();
  },
};
</script>

<style scoped>
/* // 左侧菜单列表 */
.left_box {
  width: 110px;
  height: 100%;
  background-color: #efefef;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.menuList .menu_item {
  padding: 12px 10px;
  cursor: pointer;
  color: #666;
}
.list > div {
  padding: 8px 0;
  border-bottom: 1px solid #ededed;
}
</style>